
.link-active{
    text-decoration: none;
    /* background-image: linear-gradient(to right, #00b4db, #0083b0); */
    background: #4278D1;
    background: -webkit-linear-gradient(top left, #4278D1, #0CACB7);
    background: -moz-linear-gradient(top left, #4278D1, #0CACB7);
    background: linear-gradient(to bottom right, #4278D1, #0CACB7);
    color: rgb(0, 0, 0);
    width: 100%;
}
.link-normal{
    width: 100%;
    text-decoration: none;
    color:#d2d2de;
}
.accord-details{
background-color: #c4f5ee;
}