*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
.container-fluid{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
    padding: 22px 0;
}
.container .card
{
    position: relative;
    width: 480px;
    height: 260px;
    box-shadow:  5px 5px 5px rgba(0, 0, 0, 0.05),
                 -5px -5px 5px rgba(227, 225, 225, 0.9),
                 5px 5px 5px rgba(0, 0, 0, 0.05),
                 -5px -5px 5px rgba(227, 225, 225, 0.9);
    border-radius: 15px;
    margin: 20px;
}
@media screen and (min-width: 1920px){
    .container .card
{
    position: relative;
    width: 760px;
    height: 360px;
    box-shadow:  5px 5px 5px rgba(0, 0, 0, 0.05),
                 -5px -5px 5px rgba(227, 225, 225, 0.9),
                 5px 5px 5px rgba(0, 0, 0, 0.05),
                 -5px -5px 5px rgba(227, 225, 225, 0.9);
    border-radius: 15px;
    margin: 20px;
}
}
.container .card .box
{
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    /* background: #ebf5fc; */
    background-color: #63a4ff;
    background-image: linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.01);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.container .card:hover .box
{
    /* transform: translateY(-50px);  */
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, #b95ce4, #4f29cd);
}
.container .card .box .content
{
    padding: 20px;
    text-align: center;
}
.container .card .box .content h2
{
    position: absolute;
    top: -5px;
    right: 30px;
    font-size: 4em;
    color: rgba(0, 0, 0, 0.02);
    transition: 0.8s;
    pointer-events: none;
}

.container .card:hover .box .content h2
{
    color: #0000000d;
}

.container .card .box .content h3
{
    font-size: 1.8em;
    color: rgb(81, 78, 78);
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 10px;
}
.container .card .box .content p
{
    font-size: 1em;
    font-weight: 300;
    color: #777;
    z-index: 1;
    transition: 0.5s;
}
.container .card:hover .box .content h3,
.container .card:hover .box .content p
{
    color: #fff;
}

.container .card .box .content .butn
{
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    background: #4f29cd;
    margin-top: 15px;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    box-shadow: 0 10px 20px hsl(120, 2%, 65%);
}

.container .card:hover .box .content .butn
{
    background: #f84480;
}
