/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body{
  background: linear-gradient(90deg, rgba(232,240,244,1) 0%, rgba(232,240,244,1) 100%);
}

::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track{
  background-color: rgb(219, 215, 215);
}

::-webkit-scrollbar-thumb{
  background-image: linear-gradient(to right top, #0c7a85, #00879c, #0094b3, #00a1cc, #0cade5);
  border-radius: 50px;
}