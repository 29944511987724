/* MobileView.css */
.mobile-view-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 16px;
  }
  
  .mobile-view-paper {
    padding: 32px;
    text-align: center;
  }
  
  .desktop-icon {
    font-size: 64px !important;
    color: #1976d2;
    margin-bottom: 16px;
  }
  
  .recommendation-box {
    margin-top: 24px;
    padding: 16px;
    background-color: #e3f2fd;
    border-radius: 4px;
  }