*{
    margin: 0;
    padding: 0;
    
}

.modalWrapper {
   width: 100%;
   height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:rgba(0, 0, 0, 0.6);
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}
.modal {
     min-width: 400px;
     min-height: 600px;
     /* background: rgba(90, 84, 84, 0.8) ; */
     
     background-color: #f8f9d2;
     
     background-image: linear-gradient(315deg, #eff1af 0%, #dbc8f8 74%);
     display: flex;
     justify-content: center;
     align-items: center;
     position: fixed;
     animation: fadeInDown 0.8s;
     box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
     overflow: hidden;
     border-radius: 5px;
     border-top: 2px solid #fff;
     
}

.btnClose {
 position: absolute;
 top: 8px;
 right: 8px;
 /* color: #fff; */
}
.btnClose:hover {
    color: rgb(255, 0, 64);
}



 @keyframes fadeInDown{
    0% {
        opacity: 0;
        transform: translateY(-70px);

    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
} 